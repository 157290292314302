/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     WWW.OUESTADAM.COM Web App Code                          !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifMain.js                                      !
  !  Desc. : www.ouestadam.com Locale switcher                  !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 03/12/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";

/*
--- Ouestadam products
*/
import {wwwMainRefreshPage_f} from "./wwwMain";

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : WwwLocaleSwitcherJSX                           !
  ! Description: JSX Locale switcher                            !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function WwwLocaleSwitcherJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;

    /*
    --- Return the Switcher
    */
    return (
        <ToggleButtonGroup
            orientation="horizontal"
            value={locCtx.config_o.locale}
            exclusive
            size="small"
            style={{backgroundColor: locColors.backgroundIcon, color: locColors.backgroundMainBlack}}
            onChange={(paramEvent, paramLocale) => {
                if (paramLocale) {
                    locCtx.config_o.locale = paramLocale;
                    locCtx.trans_o.oeComTransChangeLocale_m(paramLocale);
                    wwwMainRefreshPage_f(locCtx);
                }
            }}
        >
            <Tooltip title="Français">
                <ToggleButton value="fr-FR" aria-label="fr-FR">
                    FR
                </ToggleButton>
            </Tooltip>
            <Tooltip title="English">
                <ToggleButton value="en-GB" aria-label="en-GB">
                    EN
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
}
