/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     WWW.OUESTADAM.COM Web App Code                          !
  !                                                             !
  !     OUESTADAM www Web App Code                              !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : wwwMain.js                                         !
  !  Desc. : www.ouestadam.com Front Server main page           !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 04/12/2024                                         !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React, {useLayoutEffect, useState} from 'react';
import {
    Avatar, Box,
    createTheme,
    CssBaseline, IconButton,
    Paper,
    ThemeProvider, Typography
} from "@mui/material";
import {enUS, frFR} from "@mui/x-data-grid";
import {createRoot} from "react-dom/client";

/*
--- Ouestadam products
*/
import {wwwDefDevice, wwwDefOrientation, wwwDefPageType, wwwDefUrl} from "./wwwDef";
import {WwwLocaleSwitcherJSX} from "./wwwLocale";

/*
--- Ouestadam resources
*/
import wwwImgBackground from "./images/wwwBackground01.jpg";
import wwwImgDenis from "./images/Denis_Esteve_Janvier_2024_Medium.jpg";
import wwwImgAddress from "./images/wwwAddress.png";
import wwwImgEmail from "./images/wwwEmail.png";
import wwwImgGPL from "./images/gplv3-or-later.png";
import wwwImgOeTaskReport from "./images/oeTaskReport.png";

/*=============== Local JSX components =========================*/

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCVOneSkill                               !
! Description: JSX One Skill CV paper for Mobile and Desktop  !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCVOneSkill(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locStyle = paramProps.style;
    const locSkillId = paramProps.skillId;
    const locDelay = paramProps.delay;
    /*
    --- Complete common Paper Style
    */
    locStyle.overflow = (locCtx.window_o.device === wwwDefDevice.mobile) ? "hidden" : "auto";
    locStyle.borderRadius = (locCtx.window_o.device === wwwDefDevice.mobile) ? "" : "16px";
    locStyle.opacity = "0%";
    locStyle.background = "linear-gradient(to bottom right, " + locColors.backgroundCoordinateStart + " 0%, " +
        locColors.backgroundCoordinateEnd + " 100%)";
    locStyle.animation = "paperAppear 1s";
    locStyle.animationFillMode = "forwards";
    locStyle.animationDelay = locDelay;
    /*
    --- Return a Skill Paper
    */
    return (
        <Paper elevation={2} style={locStyle}>
            <Box sx={{display: "flex", justifyContent: "center", mt: "8px"}}>
                <Typography
                    component="span"
                    color={locColors.foregroundMain}
                    variant={(locCtx.window_o.device === wwwDefDevice.mobile) ? "h6" : "h4"}
                    sx={{
                        textAlign: "center",
                        fontFamily: "Patrick Hand, cursive",
                        fontWeight: 500,
                        fontStyle: "normal"
                    }}
                >
                    {locTrans.oeComTransGet_m(locSkillId, "title")}
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "left",
                mt: "8px",
                ml: ((locCtx.window_o.device === wwwDefDevice.mobile) ? "10px" : "30px")
            }}>
                <Typography
                    component="span"
                    color={locColors.foregroundMain}
                    variant={(locCtx.window_o.device === wwwDefDevice.mobile) ? "body1" : "h5"}
                    sx={{
                        textAlign: "left",
                        fontFamily: "Patrick Hand, cursive",
                        fontWeight: 500,
                        fontStyle: "normal",
                        lineHeight: "26px"
                    }}
                >
                    {locTrans.oeComTransGet_m(locSkillId, "line1")}
                    {(locSkillId === "skillFreeware") ? (
                        <a href={locCtx.config_o.urlFreeware} target="_blank" rel="noreferrer">
                            {locCtx.config_o.urlFreeware}
                        </a>
                    ) : ""}
                    <br/>
                    {locTrans.oeComTransGet_m(locSkillId, "line2")}
                    {(locSkillId === "skillFreeware") ? (
                        <a href={locCtx.config_o.urlACEsteve} target="_blank" rel="noreferrer">
                            {locCtx.config_o.urlACEsteve}
                        </a>
                    ) : ""}
                    <br/>
                    {locTrans.oeComTransGet_m(locSkillId, "line3")}
                    <br/>
                    {locTrans.oeComTransGet_m(locSkillId, "line4")}
                </Typography>
            </Box>
        </Paper>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCVSkillsDesktop                          !
! Description: JSX Skills CV paper for Desktop                !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCVSkillsDesktop(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Create the Coordinate paper
    */
    return (
        <div>
            <style>
                {`
                @keyframes paperAppear {
                    from {opacity: 0%;}
                    to {opacity: 90%;}
                }
            `}
            </style>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillDev" delay="0.5s"
                              style={{
                                  position: "absolute",
                                  top: "30px",
                                  left: "360px",
                                  height: "190px",
                                  width: (locCtx.window_o.width - 490) + "px"
                              }}/>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillVirtual" delay="1s"
                              style={{
                                  position: "absolute",
                                  top: "250px",
                                  left: "360px",
                                  height: "160px",
                                  width: (locCtx.window_o.width - 490) + "px"
                              }}/>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillInfra" delay="1.5s"
                              style={{
                                  position: "absolute",
                                  top: "440px",
                                  left: "360px",
                                  height: "140px",
                                  width: (locCtx.window_o.width - 490) + "px"
                              }}/>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillBuild" delay="2s"
                              style={{
                                  position: "absolute",
                                  top: "610px",
                                  left: "360px",
                                  height: "140px",
                                  width: (locCtx.window_o.width - 490) + "px",
                              }}/>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillFreeware" delay="2.5s"
                              style={{
                                  position: "absolute",
                                  top: "780px",
                                  left: "360px",
                                  height: "140px",
                                  width: (locCtx.window_o.width - 490) + "px",
                              }}/>
        </div>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCVSkillsMobile                           !
! Description: JSX Skills CV paper for Mobile                 !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCVSkillsMobile(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Create the Coordinate paper
    */
    return (
        <div>
            <style>
                {`
                @keyframes paperAppear {
                    from {opacity: 0%;}
                    to {opacity: 90%;}
                }
            `}
            </style>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillDev" delay="1s"
                              style={{
                                  marginTop: "370px",
                                  width: "100%"
                              }}/>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillVirtual" delay="2s"
                              style={{
                                  marginTop: "20px",
                                  width: "100%"
                              }}/>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillInfra" delay="3s"
                              style={{
                                  marginTop: "20px",
                                  width: "100%"
                              }}/>
            <LocJSXCVOneSkill ctx={locCtx} skillId="skillBuild" delay="4s"
                              style={{
                                  marginTop: "20px",
                                  width: "100%"
                              }}/>
        </div>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCVUrlLink                                !
! Description: JSX CV URL Link to Freeware                    !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCVUrlLink(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return URL Link to Freeware page
    */
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: "160px",
            width: "100%",
        }}>
            <Typography
                component="span"
                color={locColors.foregroundMain}
                variant="h6"
                sx={{
                    lineHeight: "20px",
                    textWrap: "nowrap",
                    textAlign: "center",
                    fontFamily: "Patrick Hand, cursive",
                    fontWeight: 500,
                    fontStyle: "normal",
                }}
            >
                {locTrans.oeComTransGet_m("coordinate", "labelFreeware")}
                <br/>
                <a href={locCtx.config_o.urlFreeware} target="_blank" rel="noreferrer">
                    {locCtx.config_o.urlFreeware}
                </a>
            </Typography>
        </Box>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXFreewareUrlLink                          !
! Description: JSX Freeware URL Link to CV                    !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXFreewareUrlLink(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return URL Link to Freeware page
    */
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: "160px",
            width: "100%",
        }}>
            <Typography
                component="span"
                color={locColors.foregroundMain}
                variant="h5"
                sx={{
                    lineHeight: "20px",
                    textWrap: "nowrap",
                    textAlign: "center",
                    fontFamily: "Patrick Hand, cursive",
                    fontWeight: 500,
                    fontStyle: "normal",
                }}
            >
                {locTrans.oeComTransGet_m("coordinate", "labelCV")}
                <br/>
                <a href={locCtx.config_o.urlCV} target="_blank" rel="noreferrer">
                    {locCtx.config_o.urlCV}
                </a>
            </Typography>
        </Box>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCoordinateDesktop                        !
! Description: JSX Coordinate CV and Freeware paper Desktop   !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCoordinateDesktop(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locType = paramProps.type;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Build CV or Freeware Link
    */
    let locLink_jsx;
    if (locCtx.window_o.height < 740) {
        locLink_jsx = (<div></div>);
    } else {
        if ((locType === wwwDefPageType.cv)) {
            locLink_jsx = (<LocJSXCVUrlLink ctx={locCtx}/>);
        } else {
            locLink_jsx = (<LocJSXFreewareUrlLink ctx={locCtx}/>);
        }
    }
    /*
    --- Build Company name display
    */
    const locCompany_jsx = (locCtx.window_o.height < 628) ? (<div></div>) : (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: "30px",
            width: "100%",
        }}>
            <Typography
                component="span"
                color={locColors.foregroundMain}
                variant="body1"
                sx={{
                    lineHeight: "20px",
                    textWrap: "nowrap",
                    textAlign: "center",
                    fontFamily: "Patrick Hand, cursive",
                    fontWeight: 500,
                    fontStyle: "normal",
                }}
            >
                {locTrans.oeComTransGet_m("coordinate", "company")}
                <br/>
                {locTrans.oeComTransGet_m("coordinate", "siret")}
                <br/>
                {locTrans.oeComTransGet_m("coordinate", "ape")}
            </Typography>
        </Box>
    );
    /*
    --- Create the Coordinate paper
    */
    return (
        <div>
            <style>
                {`
                @keyframes paperMove {
                    from {left: -300px;}
                    to {left: 30px;}
                }
            `}
            </style>
            <Paper elevation={2}
                   style={{
                       borderRadius: "16px",
                       position: "absolute",
                       top: "30px",
                       left: "30px",
                       height: (locCtx.window_o.height - 60) + "px",
                       width: "300px",
                       opacity: "90%",
                       background: "linear-gradient(to bottom, " + locColors.backgroundCoordinateEnd + " 0%, " +
                           locColors.backgroundCoordinateStart + " 100%)",
                       animation: (locType === wwwDefPageType.cv) ? "paperMove 0.5s" : ""
                   }}>
                <Box sx={{display: "flex", justifyContent: "center", mt: "40px"}}>
                    <IconButton
                        color="info"
                        onClick={() => window.open(locCtx.config_o.urlCV, "_blank")}
                    >
                        <Avatar
                            alt="Denis Esteve picture"
                            src={wwwImgDenis}
                            sx={{width: "180px", height: "180px"}}
                        />
                    </IconButton>
                </Box>
                <Box sx={{display: "flex", justifyContent: "center", mt: "16px"}}>
                    <Typography
                        component="span"
                        color={locColors.foregroundMain}
                        variant="h4"
                        sx={{
                            textAlign: "center",
                            fontFamily: "Patrick Hand, cursive",
                            fontWeight: 500,
                            fontStyle: "normal"
                        }}
                    >
                        {locTrans.oeComTransGet_m("coordinate", "name")}
                    </Typography>
                </Box>
                <Box sx={{display: "flex", justifyContent: "center", mt: "10px", mb: "40px"}}>
                    <Typography
                        component="span"
                        color={locColors.foregroundMain}
                        variant="h6"
                        sx={{
                            lineHeight: "10px",
                            textWrap: "nowrap",
                            fontFamily: "Patrick Hand, cursive",
                            fontWeight: 500,
                            fontStyle: "italic"
                        }}
                    >
                        {locTrans.oeComTransGet_m("coordinate", "object")}
                    </Typography>
                </Box>
                <hr style={{
                    marginLeft: "10px", marginRight: "10px",
                    color: locColors.backgroundMainWhite, backgroundColor: locColors.backgroundMainWhite
                }}/>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "20px"
                }}>
                    <img src={wwwImgAddress} alt={"address"}/>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "16px",
                    mb: "20px"
                }}>
                    <img src={wwwImgEmail} alt={"Email"}/>
                </Box>
                <hr style={{
                    marginLeft: "10px", marginRight: "10px",
                    color: locColors.backgroundMainWhite, backgroundColor: locColors.backgroundMainWhite
                }}/>
                {locLink_jsx}
                {locCompany_jsx}
            </Paper>
        </div>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCoordinateMobile                         !
! Description: JSX Coordinate CV paper for Mobile             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCoordinateMobile(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Coordinate paper
    */
    return (
        <div>
            <style>
                {`
                @keyframes paperMove {
                    from {top: -4000px;}
                    to {top: 0px;}
                }
            `}
            </style>
            <Paper elevation={2}
                   style={{
                       overflow: "hidden",
                       zIndex: "0",
                       position: "absolute",
                       top: "0px",
                       left: "0px",
                       height: "350px",
                       width: "100%",
                       opacity: "90%",
                       background: "linear-gradient(to bottom, " + locColors.backgroundCoordinateEnd + " 0%, " +
                           locColors.backgroundCoordinateStart + " 100%)",
                       animation: "paperMove 1s"
                   }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "10px"
                }}>
                    <Avatar
                        alt="Denis Esteve picture"
                        src={wwwImgDenis}
                        sx={{width: "120px", height: "120px"}}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "6px"
                }}>
                    <Typography
                        component="span"
                        color={locColors.foregroundMain}
                        variant="h4"
                        sx={{
                            textAlign: "center",
                            fontFamily: "Patrick Hand, cursive",
                            fontWeight: 500,
                            fontStyle: "normal"
                        }}
                    >
                        {locTrans.oeComTransGet_m("coordinate", "name")}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "16px"
                }}>
                    <Typography
                        component="span"
                        color={locColors.foregroundMain}
                        variant="h6"
                        sx={{
                            lineHeight: "10px",
                            textWrap: "nowrap",
                            fontFamily: "Patrick Hand, cursive",
                            fontWeight: 500,
                            fontStyle: "italic"
                        }}
                    >
                        {locTrans.oeComTransGet_m("coordinate", "object")}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "20px"
                }}>
                    <Typography
                        component="span"
                        color={locColors.foregroundMain}
                        variant="body1"
                        sx={{
                            lineHeight: "10px",
                            textWrap: "nowrap",
                            textAlign: "center",
                            fontFamily: "Patrick Hand, cursive",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        {locTrans.oeComTransGet_m("coordinate", "company")}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "10px"
                }}>
                    <Typography
                        component="span"
                        color={locColors.foregroundMain}
                        variant="body1"
                        sx={{
                            lineHeight: "10px",
                            textWrap: "nowrap",
                            textAlign: "center",
                            fontFamily: "Patrick Hand, cursive",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        {locTrans.oeComTransGet_m("coordinate", "siret")}
                        &nbsp;
                        {locTrans.oeComTransGet_m("coordinate", "ape")}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "14px"
                }}>
                    <img src={wwwImgAddress} alt={"address"}/>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "8px"
                }}>
                    <img src={wwwImgEmail} alt={"Email"}/>
                </Box>

            </Paper>
        </div>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCVContentsDesktop                        !
! Description: JSX CV Main Page Contents for Desktop          !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCVContentsDesktop(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Create the Main page Contents
    */
    return (
        <Paper style={{
            backgroundImage: `url(${wwwImgBackground})`,
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundSize: "cover",
            height: "1000px",
            width: locCtx.window_o.width
        }}>
            <Box sx={{position: "absolute", top: "30px", right: "30px"}}>
                <WwwLocaleSwitcherJSX ctx={locCtx}/>
            </Box>
            <LocJSXCoordinateDesktop ctx={locCtx} type={wwwDefPageType.cv}/>
            <LocJSXCVSkillsDesktop ctx={locCtx}/>
        </Paper>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXCVContentsMobile                         !
! Description: JSX CV Main Page Contents for Mobile           !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXCVContentsMobile(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Create the Main page Contents
    */
    return (
        <Paper style={{
            overflowX: "hidden",
            overflowY: "hidden",
            backgroundImage: `url(${wwwImgBackground})`,
            backgroundPositionX: "center",
            backgroundSize: "auto 100%",
            backgroundAttachment: "fixed",
            height: "1300px",
            width: locCtx.window_o.width
        }}>
            <Box sx={{
                overflow: "auto",
                scrollbarWidth: "thin",
                width: "100%"
            }}>
                <Box sx={{position: "absolute", top: "10px", right: "10px", zIndex: "10"}}>
                    <WwwLocaleSwitcherJSX ctx={locCtx}/>
                </Box>
                <LocJSXCoordinateMobile ctx={locCtx}/>
                <LocJSXCVSkillsMobile ctx={locCtx}/>
            </Box>
        </Paper>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXFreewareApp1Desktop                      !
! Description: JSX First Application paper for Desktop        !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXFreewareApp1Desktop(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Coordinate paper
    */
    return (
        <Paper elevation={2} style={{
            position: "absolute",
            top: "30px",
            left: "350px",
            paddingBottom: "30px",
            width: (locCtx.window_o.width - 470) + "px",
            height: "320px",
            overflow: "auto",
            borderRadius: "16px",
            background: "linear-gradient(to bottom right, " +
                locColors.backgroundCoordinateStart + " 0%, " +
                locColors.backgroundCoordinateEnd + " 100%)"
        }}>
            <Box sx={{display: "flex", justifyContent: "center", mt: "8px", mb: "8px"}}>
                <Avatar
                    alt="GNU GPL Icon"
                    src={wwwImgOeTaskReport}
                    sx={{width: "48px", height: "48px", mt: "2px", mr: "30px"}}
                />
                <Typography
                    component="span"
                    color={locColors.foregroundMain}
                    variant={(locCtx.window_o.device === wwwDefDevice.mobile) ? "h6" : "h4"}
                    sx={{
                        textAlign: "center",
                        fontFamily: "Patrick Hand, cursive",
                        fontWeight: 500,
                        fontStyle: "normal",
                    }}
                >
                    {locTrans.oeComTransGet_m("freeware", "app1Title")}
                </Typography>
                <IconButton
                    color="info"
                    onClick={() => window.open(locCtx.config_o.urlLicenses, "_blank")}
                    sx={{ml: "20px"}}
                >
                    <Avatar
                        alt="GNU GPL Icon"
                        variant="square"
                        src={wwwImgGPL}
                        sx={{width: "75px", height: "32px"}}
                    />
                </IconButton>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "left",
                mt: "20px",
                ml: "30px"
            }}>
                <Typography
                    component="span"
                    color={locColors.foregroundMain}
                    variant="h5"
                    sx={{
                        textAlign: "left",
                        fontFamily: "Patrick Hand, cursive",
                        fontWeight: 500,
                        fontStyle: "normal",
                        lineHeight: "26px"
                    }}
                >
                    {locTrans.oeComTransGet_m("freeware", "app1Line1")}
                    <br/>
                    {locTrans.oeComTransGet_m("freeware", "app1Line2")}
                    <br/><br/>
                    {locTrans.oeComTransGet_m("freeware", "app1Line3")}
                    <a href={locCtx.config_o.urlDownloadOetaskreportWindows} target="_blank" rel="noreferrer">
                        {locTrans.oeComTransGet_m("freeware", "download")}
                    </a>
                    {" (V" + locCtx.config_o.versionOetaskreport + ")"}
                    <br/>
                    {locTrans.oeComTransGet_m("freeware", "app1Line4")}
                    <a href={locCtx.config_o.urlDownloadOetaskreportDebian} target="_blank" rel="noreferrer">
                        {locTrans.oeComTransGet_m("freeware", "download")}
                    </a>
                    {" (V" + locCtx.config_o.versionOetaskreport + ")"}
                    <br/>
                    {locTrans.oeComTransGet_m("freeware", "app1Line5")}
                    <a href={locCtx.config_o.urlDownloadOetaskreportRedhat} target="_blank" rel="noreferrer">
                        {locTrans.oeComTransGet_m("freeware", "download")}
                    </a>
                    {" (V" + locCtx.config_o.versionOetaskreport + ")"}
                    <br/><br/>
                    {locTrans.oeComTransGet_m("freeware", "app1Line6")}
                    <a href={locCtx.config_o.urlGithubOeTaskReport} target="_blank" rel="noreferrer">
                        {locTrans.oeComTransGet_m("freeware", "gitHub")}
                    </a>
                </Typography>
            </Box>
        </Paper>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXFreewareLinksDesktop                     !
! Description: JSX Links paper for Freeware Desktop           !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXFreewareLinksDesktop(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Coordinate paper
    */
    return (
        <Paper elevation={2} style={{
            position: "absolute",
            top: "380px",
            left: "350px",
            paddingBottom: "30px",
            width: (locCtx.window_o.width - 470) + "px",
            height: "204px",
            overflow: "auto",
            borderRadius: "16px",
            background: "linear-gradient(to bottom right, " +
                locColors.backgroundCoordinateStart + " 0%, " +
                locColors.backgroundCoordinateEnd + " 100%)"
        }}>
            <Box sx={{display: "flex", justifyContent: "center", mt: "8px", mb: "8px"}}>
                <Typography
                    component="span"
                    color={locColors.foregroundMain}
                    variant={(locCtx.window_o.device === wwwDefDevice.mobile) ? "h6" : "h4"}
                    sx={{
                        textAlign: "center",
                        fontFamily: "Patrick Hand, cursive",
                        fontWeight: 500,
                        fontStyle: "normal",
                    }}
                >
                    {locTrans.oeComTransGet_m("freeware", "linksTitle")}
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "left",
                mt: "20px",
                ml: "30px"
            }}>
                <Typography
                    component="span"
                    color={locColors.foregroundMain}
                    variant="h5"
                    sx={{
                        textAlign: "left",
                        fontFamily: "Patrick Hand, cursive",
                        fontWeight: 500,
                        fontStyle: "normal",
                        lineHeight: "26px"
                    }}
                >
                    {locTrans.oeComTransGet_m("freeware", "linksLine1")}
                    <br/><br/>
                    {locTrans.oeComTransGet_m("freeware", "linksLine2")}
                    <a href={locCtx.config_o.urlACEsteve} target="_blank" rel="noreferrer">
                        {locCtx.config_o.urlACEsteve}
                    </a>
                    <br/>
                    {locTrans.oeComTransGet_m("freeware", "linksLine3")}
                    <a href={locCtx.config_o.urlCEsteve} target="_blank" rel="noreferrer">
                        {locCtx.config_o.urlCEsteve}
                    </a>
                </Typography>
            </Box>
        </Paper>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXFreewareContentsDesktop                  !
! Description: JSX Freeware Page Contents for Desktop         !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXFreewareContentsDesktop(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Create the Main page Contents
    */
    return (
        <Paper style={{
            backgroundImage: `url(${wwwImgBackground})`,
            backgroundPositionX: "center",
            backgroundPositionY: "bottom",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            height: locCtx.window_o.height,
            width: locCtx.window_o.width
        }}>
            <Box sx={{position: "absolute", top: "30px", right: "30px"}}>
                <WwwLocaleSwitcherJSX ctx={locCtx}/>
            </Box>
            <LocJSXCoordinateDesktop ctx={locCtx} type={wwwDefPageType.freeware}/>
            <LocJSXFreewareApp1Desktop ctx={locCtx}/>
            <LocJSXFreewareLinksDesktop ctx={locCtx}/>
        </Paper>
    )
}

/*+-----------------------------------------------------------+
! Routine    : LocJSXMain                                     !
! Description: JSX Main page                                  !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXMain(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;
    /*
    --- Get React state for refreshing the page
    */
    const [locMain_s, locMain_f] = React.useState(false);
    locCtx.refresh_o.main_f = locMain_f;
    locCtx.refresh_o.main_s = locMain_s;
    /*
    --- Check the orientation
    */
    locCtx.window_o.orientation = (window.matchMedia("(orientation: portrait)").matches) ?
        wwwDefOrientation.portrait : wwwDefOrientation.landscape;
    /*
    --- Define the device type
    */
    const locUserAgent = navigator.userAgent.toLowerCase();
    locCtx.window_o.agent = locUserAgent;
    const locIsMobile = /iphone|ipad|ipod|android|windows phone/g.test(locUserAgent);
    const locIsTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(locUserAgent);
    if (locIsTablet) locCtx.window_o.device = wwwDefDevice.tablet;
    else if (locIsMobile) {
        /*
        --- Mobile
        */
        locCtx.window_o.device = wwwDefDevice.mobile;
    } else {
        /*
        --- Desktop: Orientation is landscape
        */
        locCtx.window_o.device = wwwDefDevice.desktop;
        locCtx.window_o.orientation = wwwDefOrientation.landscape;
    }
    /*
    --- Set hook for Window resizing
    */
    [locCtx.window_o.width, locCtx.window_o.height] = WwwMainWindowResize_f();
    /*
    --- If the screen width or height is small then process it as Mobile
    */
    if ((locCtx.window_o.width < 800) || (locCtx.window_o.height < 720)) {
        locCtx.window_o.device = wwwDefDevice.mobile;
    }
    /*
    --- Search the theme locale
    */
    let locLocale_o = frFR;
    if (locCtx.config_o.locale === "en-GB") locLocale_o = enUS;
    const locBackGroundColor = locColors.backgroundMainBlack;
    /*
    --- Create the Theme
    */
    const locTheme = createTheme({
        palette: {
            background: {
                default: locBackGroundColor
            }
        }
    }, locLocale_o);
    /*
    --- Set a Background
    */
    const locStyle = {backgroundColor: locColors.backgroundMainBlack, overflow: "hidden"};
    /*
    --- Prepare the main page display according the Device type and the Page required
    */
    let locMainContainer;
    const locPathname = window.location.pathname;
    if (locPathname.indexOf(wwwDefUrl.freeware) >= 0) {
        /*
        --- Freeware page
        */
        locMainContainer = (
            <div>
                <LocJSXFreewareContentsDesktop ctx={locCtx}/>
            </div>
        );
    } else {
        /*
        --- CV Page
        */
        switch (locCtx.window_o.device) {
            case wwwDefDevice.mobile :
                locMainContainer = (
                    <div>
                        <LocJSXCVContentsMobile ctx={locCtx}/>
                    </div>
                );
                break;
            default :
                locMainContainer = (
                    <div>
                        <LocJSXCVContentsDesktop ctx={locCtx}/>
                    </div>
                );
                break;
        }
    }
    /*
    --- Return the Main page
    */
    return (<ThemeProvider theme={locTheme}>
        <CssBaseline/>
        <Paper style={locStyle}>
            {locMainContainer}
        </Paper>
    </ThemeProvider>);
}

/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine    : wwwMain_f                                      !
! Description: Main Page function                             !
!                                                             !
! IN:  - Context                                              !
! OUT: - Main page rendering                                  !
+-------------------------------------------------------------+
*/
export function wwwMain_f(paramCtx) {
    /*
    --- Render the Main page
    */
    const locRoot = createRoot(document.getElementById('root'));
    locRoot.render(
        <React.StrictMode>
            <LocJSXMain ctx={paramCtx}/>
        </React.StrictMode>);
}

/*
+-------------------------------------------------------------+
! Routine    : wwwMainRefreshPage_f                           !
! Description: Request the refresh of the Main page           !
!                                                             !
! IN:  - Context                                              !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function wwwMainRefreshPage_f(paramCtx) {
    paramCtx.refresh_o.main_s = !paramCtx.refresh_o.main_s;
    paramCtx.refresh_o.main_f(paramCtx.refresh_o.main_s);
}

/*
+-------------------------------------------------------------+
! Routine    : WwwMainWindowResize_f                          !
! Description: Manage hook for Window resizing                !
!                                                             !
! IN:  - Context                                              !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export const WwwMainWindowResize_f = () => {
    const [locWindowSize, locSetWindowSize_f] = useState([0, 0])
    const locUpdateWindowSize_f = () => {
        locSetWindowSize_f([window.innerWidth, window.innerHeight])
    }
    useLayoutEffect(() => {
        window.addEventListener('resize', locUpdateWindowSize_f);
        locUpdateWindowSize_f();
        return () => window.removeEventListener('resize', locUpdateWindowSize_f);
    }, [])
    return [locWindowSize[0], locWindowSize[1]]
}
